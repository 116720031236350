<template lang="pug">
div(class="arhibash")
    div(class="arhibash__body")
        div(v-html="post.text")
    div(class="arhibash__footer")
        z-paginate(:reverse="false" v-model="currentPage" :pages="pages" @change="paginateChange" class="forum-archive__footer__paginate")
        span(class="arhibash__footer__time") {{formattedDateTime}}
</template>

<script setup>
import axios from 'axios'
import { ref, defineEmits, defineProps, computed } from 'vue'
import ZPaginate from "../../components/z-panginate"
import { dateTimeForum } from '../../plugins/dayjs'

const props = defineProps({
    panel: {}
})

const emit = defineEmits(['append_to_route'])

const arhibashForumId = 25
const isPostsLoading = ref(false)
const isLoadingStarted = ref(false)
const currentPage = ref(-1)
const pages = ref(0)
const post = ref({})

function getPosts (page = 0) {
    isLoadingStarted.value = true
    isPostsLoading.value = false

    setTimeout(() => {
        isPostsLoading.value = true
    }, 500)

    let getParams = {
        forum_id: arhibashForumId,
        page: (page) ? page : currentPage.value,
        count: 1,
        search: ''
    }

    axios.get('get_forum_archive', { params: getParams }).then(response => {
        currentPage.value = response.data.page
        pages.value = response.data.pages

        post.value = (response.data.posts.length>0) ? response.data.posts[0] : {}
        isLoadingStarted.value = false
        isPostsLoading.value = false

        // scrollPosts(postId)
        emit('append_to_route', { page: currentPage.value })
    })
}

function paginateChange () {
    getPosts(currentPage.value)
}

getPosts()

const formattedDateTime = computed(() => {
    return dateTimeForum(post.value.time)
})

</script>

<style scoped>
    .arhibash {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .arhibash__footer {
        height: var(--block-height-big);
        min-height: var(--block-height-big);
        flex: 0;
        display: flex;
        position: relative;
    }

    .arhibash__footer__time{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        min-width: 50%;
        margin-right: var(--indent-size);
    }

    .arhibash__body {
        flex: 1;
        margin: var(--indent-size);
        padding-right: var(--indent-size);
        /*background: var(--editor-background);*/
        display: flex;
        word-break: break-word;
        overflow-y: auto;
    }

    .arhibash__body ::v-deep(p){
        margin-bottom: var(--indent-size);
        margin-top: 0;
        text-align: justify;
        min-height: 1rem;
    }
</style>
